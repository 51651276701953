import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useSiteContext } from '../components/SiteContext';
import loading from './loading';
import InView from '../components/InView';

const hasAdunit = (newsboard) => newsboard && !([
  'FASHIONUNITED_INFO',
].includes(newsboard));

const DynamicAdUnit = dynamic(
  () => import('@fuww/library/src/Advertisement'),
  {
    loading,
    ssr: false,
  },
);

const AdUnit = ({ adUnit, ...properties }) => {
  const { adUnitPrefix } = useSiteContext();

  return (
    <InView>
      <DynamicAdUnit
        {...properties}
        adUnit={`${adUnitPrefix}/${adUnit}`}
        dfpNetworkId="53457124"
      />
    </InView>
  );
};

AdUnit.propTypes = {
  adUnit: PropTypes.string.isRequired,
};

export default AdUnit;
export { hasAdunit };
