import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';

const MobileAdUnitContainer = styled.div`
  && {
    display: block;
    border-top: 1px solid var(--grey-light);
    text-align: center;
    margin-bottom: 24px;

    @media (min-width: ${breakpoints.sm}px) {
      display: none;
    }
  }
`;

const TabletAdUnitContainer = styled.div`
  && {
    display: none;

    @media (min-width: ${breakpoints.sm}px) {
      display: block;
      border-top: 1px solid var(--grey-light);
      padding: 24px 0;
      margin-bottom: 24px;
    }

    @media (min-width: ${breakpoints.lg}px) {
     display: none;
    }
  }
`;

const DesktopAdUnitContainer = styled.div`
  && {
    display: none;

    @media (min-width: ${breakpoints.lg}px) {
      display: block;
      border-top: 1px solid var(--grey-light);
      padding: 24px 0;
      margin-bottom: 24px;
    }
  }
`;

export {
  DesktopAdUnitContainer,
  TabletAdUnitContainer,
  MobileAdUnitContainer,
};
