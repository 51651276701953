import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';

const spacingContainerPropertyTypes = {
  marginBottom: PropTypes.string,
};

type SpacingContainerProperties = InferProps<
  typeof spacingContainerPropertyTypes
>;

const SpacingContainer = styled.div<SpacingContainerProperties>`
  margin-bottom: ${({ marginBottom = '24px' }) => marginBottom};
`;

SpacingContainer.propTypes = spacingContainerPropertyTypes;

export default SpacingContainer;
